import axios from '@/utils/request'

//商户统计列表
export function shopStatistics(params) {
  // params.loading = true
    return axios({
      url: `/manager/data/analysis/merchants`,
      method: 'get',
      params
    })
  }

  // 店铺统计列表
  export function storeStatistics(params) {
    // params.loading = true
    return axios({
      url: `/manager/data/analysis/stores`,
      method: 'get',
      params
    })
  }
  
  // 时段分析列表
  export function timeStatistics(params) {
    // params.loading = true
    return axios({
      url: `/manager/data/analysis/times`,
      method: 'get',
      params
    })
  }
  // ip制导分析
  export function ipStatistics(params) {
    // params.loading = true
    return axios({
      url: `/manager/data/ip/orders/times`,
      method: 'get',
      params
    })
  }
  // ip制导导出
  export function exportIp(params) {
    return axios({
      responseType:'blob',
      url: `/manager/data/ip/orders/export`,
      method: 'post',
      params
    })
  }