<template>
  <div id="mian">
    <!-- 批量订单查询 -->
    <div>
      <!-- 上面表单框 -->
      <div class="con_from">
        <div class="lineP">
          <div class="item">
            <i>商户名称</i>
            <el-input
              v-model="query.keyword"
              maxlength="128"
              suffix-icon="el-icon-search"
              placeholder="商户名称/编号"
            ></el-input>
          </div>
          <!-- <el-select v-model="query.mchId" placeholder="全部" style="width:200px;height:36px;">
            <el-option
              v-for="item in MerchantsDropList"
              :key="item.mchId"
              :label="item.mchName"
              :value="item.mchId">
            </el-option>
          </el-select> -->
          <div class="item">
            <i>产品类型</i>
            <el-select
              v-model="query.productId"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="item in payproductsDropList"
                :key="item.productId"
                :label="item.productName"
                :value="item.productId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <i>统计维度</i>
            <el-select
              v-model="query.totalDimension"
              placeholder="全部"
            >
              <el-option label="商户" value="MERCHANT"></el-option>
              <el-option label="店铺" value="STORE"></el-option>
              <el-option label="报备商户号" value="TRADE_MERCHANTS"></el-option>
            </el-select>
          </div>
        </div>
        <div class="lineP">
          <div class="item">
            <i>风控状态</i>
            <el-select
              v-model="query.riskSituation"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="正常" value="NORMAL"></el-option>
              <el-option label="异常" value="EXCEPTION"></el-option>
            </el-select>
          </div>
          <div class="item">
            <i>时段选择</i>
            <el-select
              v-model="query.countPeriod"
              placeholder="全部"
            >
              <el-option label="1分钟" value="MINUTE"></el-option>
              <el-option label="3分钟" value="T_MINUTE"></el-option>
              <el-option label="5分钟" value="F_MINUTE"></el-option>
              <el-option label="15分钟" value="QUARTER"></el-option>
              <el-option label="30分钟" value="HALFHOUR"></el-option>
              <el-option label="60分钟" value="HOUR"></el-option>
            </el-select>
          </div>
          <div class="item">
            <i>显示方式</i>
            <el-select
              v-model="query.showType"
              placeholder="全部"
            >
              <el-option label="列表" value="LIST"></el-option>
              <el-option label="图标" value="GRAPH"></el-option>
            </el-select>
          </div>
        </div>
        <div class="lineP">
          <div class="item itemTime">
            <i>统计时间</i>
            <el-date-picker
              v-model="query.startTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">-</span>
            <el-date-picker
              v-model="query.endTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            >
            </el-date-picker>
          </div>
          <div class="itembtn">
            <el-button class="searchBt" @click="onSearch">查询</el-button>
          </div>
        </div>
      </div>
      <!-- 中间交易账目 -->
      <div class="jiaoyi">
        <div class="jiaoyi_box">
          <p><i>订单笔数（笔）</i></p>
          <p>
            <b>{{ statistics.orderNum }}</b>
          </p>
        </div>
        <div class="jiaoyi_box">
          <p><i>成功订单（笔）</i></p>
          <p>
            <b>{{ statistics.tradeNum }}</b>
          </p>
        </div>
        <div class="jiaoyi_box">
          <p><i>成功率（%）</i></p>
          <p>
            <b>{{ statistics.successRate }}</b>
          </p>
        </div>
        <div class="jiaoyi_box">
          <p><i>交易总额（元）</i></p>
          <p>
            <b>{{ (statistics.tradeAmount / 100) | formatMoney }}</b>
          </p>
        </div>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>统计时间(起/止)</p>
            </td>
            <td>
              <p>商户名称</p>
              <p v-show="query.totalDimension == 'MERCHANT'">商户编号</p>
              <p
                v-show="
                  query.totalDimension == 'TRADE_MERCHANTS' ||
                  query.totalDimension == 'STORE' ||
                  query.totalDimension == ''
                "
              >
                店铺名称
              </p>
            </td>
            <td>
              <p
                v-show="
                  query.totalDimension == 'TRADE_MERCHANTS' ||
                  query.totalDimension == ''
                "
              >
                报备商户
              </p>
              <p
                v-show="
                  query.totalDimension == 'TRADE_MERCHANTS' ||
                  query.totalDimension == ''
                "
              >
                报备商户号
              </p>
            </td>
            <td>
              <p>产品类型</p>
            </td>
            <td>
              <p>订单笔数(笔)</p>
            </td>
            <td>
              <p>成功订单(笔)</p>
            </td>
            <td>
              <p>成功率(%)</p>
            </td>
            <td>
              <p>交易总额(元)</p>
            </td>
            <td>
              <p>风控状态</p>
            </td>
          </tr>
          <tr v-for="(item, index) in tableData" :key="index">
            <td>
              <p>{{ item.startTime }}</p>
              <p>{{ item.endTime }}</p>
            </td>
            <td>
              <p>{{ item.mchName }}</p>
              <p v-show="query.totalDimension == 'MERCHANT'">{{ item.mchId }}</p>
              <p
                v-show="
                  query.totalDimension == 'TRADE_MERCHANTS' ||
                  query.totalDimension == 'STORE' ||
                  query.totalDimension == ''
                "
              >
                {{ item.storeName }}
              </p>
            </td>
            <td>
              <p
                v-show="
                  query.totalDimension == 'TRADE_MERCHANTS' ||
                  query.totalDimension == ''
                "
              >
                {{ item.tradeMchName }}
              </p>
              <p
                v-show="
                  query.totalDimension == 'TRADE_MERCHANTS' ||
                  query.totalDimension == ''
                "
              >
                {{ item.tradeMchNo }}
              </p>
            </td>
            <td>
              <p>{{ item.productName }}</p>
            </td>
            <td>
              <p>{{ item.orderNum }}</p>
            </td>
            <td>
              <p>{{ item.tradeNum }}</p>
            </td>
            <td>
              <p>{{ item.successRate }}</p>
            </td>
            <td>
              <p>{{ (item.tradeAmount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p v-if="item.riskSituation == 'NORMAL'">正常</p>
              <p v-if="item.riskSituation == 'EXCEPTION'">异常</p>
            </td>
          </tr>
        </table>
      </div>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNum"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { merchantsDrop, payproductsDrop } from "@/api/common.js";
import { formatDate } from "@/utils/common.js";
import { timeStatistics } from "@/api/data/data.js";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      value1: "",
      value2: "",
      crumbs: "批量查询",
      query: {
        pageNo: 1,
        pageSize: 10,
        mchId: "",
        startTime: formatDate(+new Date(), "yyyy-MM-dd 00:00:00"),
        endTime: formatDate(+new Date(), "yyyy-MM-dd 23:59:59"),
        productId: "",
        totalDimension: "MERCHANT",
        riskSituation: "",
        countPeriod: "QUARTER",
        showType: "LIST",
        keyword: "",
      },
      MerchantsDropList: [], //所属商户
      payproductsDropList: [], // 产品类型
      tableData: [],
      totalNum: 0,
      statistics: {
        orderNum: 0,
        successRate: 0,
        tradeAmount: 0,
        tradeNum: 0,
      },
    };
  },
  activated() {
    this.getLists();
    this.getMerchantsDrop();
    this.getPayproductsDrop();
  },
  computed: {
    ...mapState({
      sdfxDetail: "data_sdfxQuery",
    }),
  },
  created() {
    if (this.sdfxDetail) {
      this.query = this.sdfxDetail.query;
    }
    this.getLists(false);
    this.getMerchantsDrop();
    this.getPayproductsDrop();
    this.$enter(this.$route.path, this.onSearch);
  },
  methods: {
    ...mapMutations({
      setSdfxDeal: "data_setSdfx",
    }),
    //菜单切换
    navClick(data) {
      this.crumbs = data;
    },
    getLists(reset = true) {
      if (reset) {
        (this.query.pageNo = 1),
          (this.query.keyword = this.query.keyword.trim());
      }
      if (!this.query.startTime || !this.query.endTime) {
        this.$message.error("统计日期时间段必选");
        return;
      }
      timeStatistics(this.query)
        .then((res) => {
          if (res) {
            this.tableData = res.resultData.settles;
            if (this.tableData.length == 0) {
              this.$message({
                message: "暂无数据",
                duration: 1500,
              });
            }
            this.statistics = res.resultData.statistics; // 汇总统计
            this.totalNum = res.resultData.totalNum;
          }
        })
        .catch();
    },
    // 获取商户下拉
    getMerchantsDrop() {
      merchantsDrop()
        .then((res) => {
          this.MerchantsDropList = res.resultData;
        })
        .catch();
    },
    getPayproductsDrop() {
      payproductsDrop()
        .then((res) => {
          this.payproductsDropList = res.resultData;
        })
        .catch();
    },
    onSearch() {
      this.getLists();
    },
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
    };
    this.setSdfxDeal(data);
  },
};
</script>
<style scoped>
.con_from .lineP .itemTime {
    width: 49.58%;
}
</style>
